import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./useAuthContext";

export const useLogout = () => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();

  const logout = () => {
    // remove token from storage
    sessionStorage.clear();

    // dispatch logout action
    dispatch({ type: "LOGOUT" });

    navigate("/admin/login");
  };

  return { logout };
};
