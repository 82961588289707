import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MobileStepper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { EyeOff, Eye } from "lucide-react";
import * as yup from "yup";
import { ref } from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useAuthContext } from "../hooks/useAuthContext";
import { ReactComponent as Logo } from "../resources/images/logo.svg";
import { useLogout } from "../hooks/useLogout";
import { Link } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";

const initialValues = { email: "", password: "", confirmPassword: "" };

const validationSchema = yup.object({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf(
      [ref("password"), null],
      "* Password does not match"
    ),
});

const CreateNewPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { dispatch } = useAuthContext();
  const { logout } = useLogout();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  const [texts, setTexts] = useState({ heading: "", subHeading: "" });

  useEffect(() => {
    const getTexts = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/onboarding`)

        .then((resource) => {
          setTexts({
            heading: resource?.data?.data?.heading || "",
            subHeading: resource?.data?.data?.subHeading || "",
          });
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message || "", {
            variant: "error",
          });
          if (error?.response?.data?.statusCode === 401) {
            logout();
          }
        });
    };
    getTexts();
  }, []);

  const formikChangePassword = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const config = {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-new-password`,
          { ...values, email: localStorage.getItem("email") },
          config
        );

        if (response?.data) {
          enqueueSnackbar(response?.data?.message, {
            preventDuplicate: true,
            variant: "success",
          });
        }
        navigate("/admin/login");
      } catch (error) {
        enqueueSnackbar(
          error?.response?.data?.message || "Some error occurred",
          { variant: "error" }
        );

        if (error?.response?.data?.statusCode === 401) {
          logout();
        }
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>Create new password | {process.env.REACT_APP_APP_NAME}</title>
      </Helmet>
      <Grid container height="100%">
        <Grid item xs={12} md={6.5}>
          <Stack
            justifyContent="center"
            alignItems="center"
            height="100%"
            px={2}
          >
            <Stack
              bgcolor="common.white"
              maxWidth={{ xs: "100%", sm: "80%" }}
              width="100%"
              minHeight="50%"
              justifyContent="center"
              alignItems="center"
              paddingTop={8}
              paddingBottom={10}
              paddingX={{ xs: 3, sm: 4 }}
              borderRadius={2.5}
            >
              <form
                onSubmit={formikChangePassword.handleSubmit}
                style={{ width: "100%" }}
              >
                {console.log(formikChangePassword.errors)}
                <Stack
                  maxWidth={{ xs: 450, xl: 500 }}
                  mx="auto"
                  width="100%"
                  alignItems="center"
                >
                  <Box maxWidth={150} m={"auto"}>
                    <Logo width="100%" height="100%" />
                  </Box>
                  <Box width="100%" mt={1.25}>
                    <Typography
                      variant="h2"
                      color="common.black"
                      fontWeight={600}
                      sx={{ mt: 1.25 }}
                      textAlign="center"
                    >
                      Create a new password
                    </Typography>
                  </Box>
                  <Box width="100%" sx={{ mt: 6 }}>
                    <InputLabel sx={{ mt: 1 }} htmlFor="password">
                      Password{" "}
                      <Typography
                        color="error"
                        display="inline"
                        fontWeight={500}
                      >
                        *
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ mt: 0.5 }}
                      type={showPassword1 ? "text" : "password"}
                      fullWidth
                      variant="outlined"
                      id="password"
                      name="password"
                      placeholder="Password"
                      value={formikChangePassword.values.password}
                      onChange={formikChangePassword.handleChange}
                      error={
                        formikChangePassword.touched.password &&
                        Boolean(formikChangePassword.errors.password)
                      }
                      helperText={
                        formikChangePassword.touched.password &&
                        formikChangePassword.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              tabIndex={-1}
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword1()}
                            >
                              {showPassword1 ? (
                                <LuEye color="#130F26" />
                              ) : (
                                <LuEyeOff color="#130F26" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <InputLabel sx={{ mt: 1 }} htmlFor="confirmPassword">
                      Confirm password{" "}
                      <Typography
                        color="error"
                        display="inline"
                        fontWeight={500}
                      >
                        *
                      </Typography>
                    </InputLabel>
                    <TextField
                      sx={{ mt: 0.5 }}
                      type={showPassword2 ? "text" : "password"}
                      fullWidth
                      variant="outlined"
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      value={formikChangePassword.values.confirmPassword}
                      onChange={formikChangePassword.handleChange}
                      error={
                        formikChangePassword.touched.confirmPassword &&
                        Boolean(formikChangePassword.errors.confirmPassword)
                      }
                      helperText={
                        formikChangePassword.touched.confirmPassword &&
                        formikChangePassword.errors.confirmPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              tabIndex={-1}
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword2()}
                            >
                              {showPassword2 ? (
                                <LuEye color="#130F26" />
                              ) : (
                                <LuEyeOff color="#130F26" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{ mt: 3 }}
                    onClick={() => formikChangePassword.submitForm()}
                    loading={isLoading}
                    loadingIndicator={
                      <CircularProgress color="secondary" size={22} />
                    }
                  >
                    <span>Save</span>
                  </LoadingButton>
                </Stack>
              </form>
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={5.5} display={{ xs: "none", md: "block" }}>
          <Box bgcolor="primary.main" height="100%">
            <Stack alignItems="center" height="100%" justifyContent="center">
              <Stack
                height={{ xs: "68%", xl: "50%" }}
                alignItems="center"
                justifyContent="flex-end"
                px={4}
              >
                <Box mb={3}>
                  <Typography
                    variant="h1"
                    fontWeight={600}
                    textAlign="center"
                    color="common.white"
                  >
                    {texts.heading}
                  </Typography>
                  <Typography
                    mt={1.5}
                    variant="subtitle1"
                    fontWeight={400}
                    textAlign="center"
                    color="common.white"
                    lineHeight={1.6}
                    maxWidth={400}
                    mx="auto"
                  >
                    {texts.subHeading}
                  </Typography>
                </Box>
                <MobileStepper
                  variant="dots"
                  steps={3}
                  position="static"
                  activeStep={0}
                  sx={{ maxWidth: 400, flexGrow: 1 }}
                />
              </Stack>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateNewPassword;
