import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { retry } from "../common/utils/CommonFunctions";
import { useAuthContext } from "../hooks/useAuthContext";
import Loader from "../components/Loader";
// import Home from "../pages/Home";
// import AboutUs from "../pages/AboutUs";
// import BecomeAFigMaster from "../pages/BecomeAFigMaster";
// import Partnership from "../pages/Partnership";
// import Investor from "../pages/Investor";
// import News from "../pages/News";
// import Safety from "../pages/Safety";
import CreateNewPassword from "../pages/CreateNewPassword";
import PlayerGuidelines from "../pages/PlayerGuidelines";
import EventCodeOfConduct from "../pages/EventCodeOfConduct";

const FourOhFour = lazy(() => retry(() => import("../pages/FourOhFour")));
const Login = lazy(() => retry(() => import("../pages/Login")));
const ForgotPassword = lazy(() =>
  retry(() => import("../pages/ForgotPassword"))
);
const AdminSidebar = lazy(() => retry(() => import("../layout/AdminSidebar")));

const Routes = () => {
  const { isLoading, state } = useAuthContext();

  const routes = useRoutes([
    {
      path: "/",
      element: state ? (
        <Navigate replace to="/admin/dashboard" />
      ) : (
        <Navigate replace to="/login" />
      ),
    },
    // {
    //   path: "/",
    //   element: <Home />,
    // },
    // {
    //   path: "/about-us",
    //   element: <AboutUs />,
    // },
    // {
    //   path: "/become-a-figmaster",
    //   element: <BecomeAFigMaster />,
    // },
    // {
    //   path: "/partnership",
    //   element: <Partnership />,
    // },
    // {
    //   path: "/investors",
    //   element: <Investor />,
    // },
    // {
    //   path: "/news",
    //   element: <News />,
    // },
    // {
    //   path: "/safety",
    //   element: <Safety />,
    // },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/create-password",
      element: <CreateNewPassword />,
    },
    {
      path: "/player-guidelines",
      element: <PlayerGuidelines />,
    },
    {
      path: "/event-code-of-conduct",
      element: <EventCodeOfConduct />,
    },
    {
      path: "/admin/login",
      element: !state ? <Login /> : <Navigate replace to="/admin/dashboard" />,
    },
    {
      path: "/admin",
      element: <Navigate replace to="/admin/login" />,
    },
    {
      path: "/admin/*",
      element: state ? (
        <AdminSidebar />
      ) : (
        <Navigate replace to="/admin/login" />
      ),
    },
    {
      path: "/*",
      element: <Navigate replace to="/admin/login" />,
    },
    {
      path: "/404",
      element: <FourOhFour />,
    },
  ]);

  return isLoading ? <Loader /> : routes;
};

export default Routes;
